import Link from 'next/link'
import Image from 'next/image'
import { Maybe, MkCarouselHeroSlide } from 'apollo/generated/wrapper/wrapper.schema.types'
import { WindowData } from '@/utils/hooks/useWindow'
import { DesktopSlideNavUi, MobileSlideNavUi } from './CarouselHero'
import { SwiperRef } from 'swiper/react'
import { RefObject } from 'react'
import {
  SlideBackgroundImage,
  ContentContainer,
  HeadlineImage,
  HeadlineText,
  SublineContainer,
  SublineHeading,
  ButtonContainer,
  StyledButtonOne,
  StyledButtonTwo,
  BottomNav,
  Root,
  ProductNameAndLogoImage,
  ProductNameAndLogoContainer,
} from './CarouselHeroSlide.styled'
import { useAmplitude } from '@/utils/hooks/useAmplitude'
import { useRouter } from 'next/router'
import { numericBreakpoints } from '@/utils/general'

export enum ProductName {
  SPORTSBOOK = 'sportsbook',
  FANTASY = 'fantasy',
  CASINO = 'casino',
}

export type CarouselHeroSlideProps = MkCarouselHeroSlide & {
  windowData: WindowData
  carouselRef: RefObject<SwiperRef>
  slideIndex: number
  activeSlideIndex: number
  numSlides: number
  moduleAnalyticsEventProperty?: Maybe<string>
  placementAnalyticsEventProperty?: Maybe<string>
  mobileSlideNavUi?: Maybe<MobileSlideNavUi>
  desktopSlideNavUi?: Maybe<DesktopSlideNavUi>
}

const CarouselHeroSlide = ({
  windowData,
  carouselRef,
  slideIndex,
  activeSlideIndex,
  numSlides,
  title,
  moduleAnalyticsEventProperty,
  placementAnalyticsEventProperty,
  mobileSlideNavUi,
  desktopSlideNavUi,
  mobileBackgroundImage,
  tabletBackgroundImage,
  desktopBackgroundImage,
  productNameAndLogo,
  customProductNameAndLogoImage,
  headlineImage,
  headlineText,
  sublineHeading,
  sublineDescription,
  buttonOneText,
  buttonOneUrl,
  buttonOneAnalyticsEventName,
  buttonOneProductAnalyticsEventProperty,
  buttonTwoText,
  buttonTwoUrl,
  buttonTwoAnalyticsEventName,
  buttonTwoProductAnalyticsEventProperty,
}: CarouselHeroSlideProps) => {
  const { logAmplitudeEvent } = useAmplitude()
  const router = useRouter()

  const { isMobile } = windowData

  const getSlideBackroundImage = () => {
    if (
      !mobileBackgroundImage?.asset?.url &&
      !tabletBackgroundImage?.asset?.url &&
      !desktopBackgroundImage?.asset?.url
    ) {
      return null
    }

    // Client side media queries faster than waiting for JS to determine correct image to load
    return (
      <picture>
        <source
          media={`(max-width: ${numericBreakpoints.sm - 1}px)`}
          srcSet={mobileBackgroundImage?.asset?.url ?? ''}
          data-testid="mobile-background-image-source"
        />
        <source
          media={`(max-width: ${numericBreakpoints.lg - 1}px)`}
          srcSet={tabletBackgroundImage?.asset?.url ?? ''}
          data-testid="tablet-background-image-source"
        />
        <source
          media={`(min-width: ${numericBreakpoints.lg}px)`}
          srcSet={desktopBackgroundImage?.asset?.url ?? ''}
          data-testid="desktop-background-image-source"
        />
        <SlideBackgroundImage alt={'Slide Background'} />
      </picture>
    )
  }

  const getProductNameAndLogoImage = () => {
    if (customProductNameAndLogoImage?.asset?.url)
      return <img src={customProductNameAndLogoImage.asset.url} alt="Product Name" />

    if (productNameAndLogo === 'sportsbook')
      return (
        <ProductNameAndLogoImage
          width={279}
          height={40}
          src={'/images/sportsbook_name_and_logo.svg'}
          alt="Sportsbook"
        />
      )

    if (productNameAndLogo === 'fantasy')
      return <ProductNameAndLogoImage width={199} height={40} src={'/images/fantasy_name_and_logo.svg'} alt="Fantasy" />

    if (productNameAndLogo === 'casino')
      return <ProductNameAndLogoImage width={181} height={40} src={'/images/casino_name_and_logo.svg'} alt="Casino" />

    return null
  }

  return (
    <Root data-testid={`${title} slide`}>
      {getSlideBackroundImage()}
      <ContentContainer>
        {getProductNameAndLogoImage() && (
          <ProductNameAndLogoContainer data-testid="product-name-container">
            {getProductNameAndLogoImage()}
          </ProductNameAndLogoContainer>
        )}

        {headlineImage?.asset?.url && <HeadlineImage src={headlineImage?.asset?.url} alt="Headline Image" />}
        {headlineText && <HeadlineText>{headlineText}</HeadlineText>}

        {(sublineHeading || sublineDescription) && (
          <SublineContainer>
            <SublineHeading>{sublineHeading}</SublineHeading>
            <p>{sublineDescription}</p>
          </SublineContainer>
        )}

        {(buttonOneUrl || buttonTwoUrl) && (
          <ButtonContainer>
            {buttonOneUrl && (
              // Buttons are only focusable if slide is active
              <Link href={buttonOneUrl} tabIndex={slideIndex === activeSlideIndex ? 0 : -1}>
                <StyledButtonOne
                  tabIndex={-1}
                  onClick={(event) => {
                    // Prevent default Link behavior -- was causing issues with buttons sometimes not navigating
                    event.preventDefault()

                    logAmplitudeEvent(buttonOneAnalyticsEventName, buttonOneUrl, {
                      Module: moduleAnalyticsEventProperty,
                      Placement: placementAnalyticsEventProperty,
                      'Link Text': buttonOneText,
                      Product: buttonOneProductAnalyticsEventProperty,
                    })

                    router.push(buttonOneUrl) // Nav here instead
                  }}
                >
                  {buttonOneText}
                </StyledButtonOne>
              </Link>
            )}
            {buttonTwoUrl && (
              <Link href={buttonTwoUrl} tabIndex={slideIndex === activeSlideIndex ? 0 : -1}>
                <StyledButtonTwo
                  tabIndex={-1}
                  onClick={(event) => {
                    event.preventDefault()

                    logAmplitudeEvent(buttonTwoAnalyticsEventName, buttonTwoUrl, {
                      Module: moduleAnalyticsEventProperty,
                      Placement: placementAnalyticsEventProperty,
                      'Link Text': buttonTwoText,
                      Product: buttonTwoProductAnalyticsEventProperty,
                    })

                    router.push(buttonTwoUrl)
                  }}
                >
                  {buttonTwoText}
                </StyledButtonTwo>
              </Link>
            )}
          </ButtonContainer>
        )}

        {numSlides > 1 &&
          ((isMobile && mobileSlideNavUi === MobileSlideNavUi.BOTTOM_NAV) ||
            (!isMobile && desktopSlideNavUi === DesktopSlideNavUi.BOTTOM_NAV)) && (
            <BottomNav className="bottomNav" data-testid="bottom-nav">
              <button
                onClick={() => {
                  carouselRef.current?.swiper?.slidePrev()
                  carouselRef.current?.swiper?.autoplay.stop()
                }}
              >
                <Image
                  src="https://s3.amazonaws.com/cdn.fanduel.com/images/2023/fanduel/homepage/facelift/prev-arrow.svg"
                  alt="Previous Slide Arrow"
                  width={18}
                  height={18}
                />
              </button>
              <span>
                {slideIndex + 1} of {numSlides}
              </span>
              <button
                onClick={() => {
                  carouselRef.current?.swiper?.slideNext()
                  carouselRef.current?.swiper?.autoplay.stop()
                }}
              >
                <Image
                  src="https://s3.amazonaws.com/cdn.fanduel.com/images/2023/fanduel/homepage/facelift/next-arrow.svg"
                  alt="Next Slide Arrow"
                  width={18}
                  height={18}
                />
              </button>
            </BottomNav>
          )}
      </ContentContainer>
    </Root>
  )
}

export default CarouselHeroSlide
