import { sanitize } from 'isomorphic-dompurify'
import { MkCustomHtmlTemplate } from 'apollo/generated/wrapper/wrapper.schema.types'

type CustomHTMLProps = {
  mk_customHtml: MkCustomHtmlTemplate
}

export default function CustomHTML({ mk_customHtml }: Readonly<CustomHTMLProps>) {
  const { mk_html } = mk_customHtml

  return (
    <div
      lang="en"
      dangerouslySetInnerHTML={{
        __html: sanitize(mk_html as string, { ADD_TAGS: ['link'], FORCE_BODY: true, FORBID_TAGS: ['title'] }),
      }}
    />
  )
}
