import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MkSubBody as MkSubBodyGQL } from 'apollo/generated/wrapper/wrapper.schema.types'

type MkSubBodyProps = Pick<MkSubBodyGQL, 'mk_bodyColor'>

export const MkSubBodyStyled = styled.div<MkSubBodyProps>`
  color: ${(props) => props.mk_bodyColor?.value || 'black'};
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 8px;
  text-align: left;
  width: 100%;

  a {
    text-decoration: none;
    cursor: pointer;
    color: white;
    display: flex;
    padding-top: 5px;
  }

  ${mediaEmotion.sm(css`
    font-size: 14px;
  `)}

  ${mediaEmotion.lg(css`
    font-size: 18px;
    margin-bottom: 15px;
  `)}
`
