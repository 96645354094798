import InfoCard from './InfoCard'
import { GridContainer } from '../styled/Cards.styled'
import { SectionHeaderContainer, WhyFanDuelContainer } from './WhyFanDuel.styled'
import { MkWhyFanDuelTemplate } from 'apollo/generated/wrapper/wrapper.schema.types'
import { GridItem } from '../Cards'

type WhyFanDuelProps = { mk_whyFanDuel: MkWhyFanDuelTemplate }

const WhyFanDuel = ({ mk_whyFanDuel }: WhyFanDuelProps) => {
  return (
    <WhyFanDuelContainer>
      <SectionHeaderContainer>
        <h2>{mk_whyFanDuel.title}</h2>
        <p>{mk_whyFanDuel.subtext}</p>
      </SectionHeaderContainer>
      <GridContainer>
        {mk_whyFanDuel.whyFanduel?.map((item) => (
          <GridItem key={item?.title} xsm={12} sm={6} md={4} lg={4} xl={4}>
            <InfoCard title={item?.title ?? ''} content={item?.html ?? ''} />
          </GridItem>
        ))}
      </GridContainer>
    </WhyFanDuelContainer>
  )
}

export default WhyFanDuel
