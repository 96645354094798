import { MkCallToAction, MkInfoCard, MkSubCtaHtp } from 'apollo/generated/wrapper/wrapper.schema.types'
import { Maybe } from 'graphql/jsutils/Maybe'
import BuilderSection from './BuilderSection'
import {
  CardLink,
  ExploreItem,
  ExploreItemContent,
  ExploreItemImage,
  ExploreItemInner,
  Overlay,
} from './styled/ExploreCard.styled'
import { useAmplitude } from '@/utils/hooks/useAmplitude'

export type ExploreCardProps = {
  mk_infoCard: Maybe<MkInfoCard>
  moduleAnalyticsEventProperty?: Maybe<string>
  placementAnalyticsEventProperty?: Maybe<string>
}

const ExploreCard: React.FC<ExploreCardProps> = ({
  mk_infoCard,
  moduleAnalyticsEventProperty,
  placementAnalyticsEventProperty,
}) => {
  const { logAmplitudeEvent } = useAmplitude()

  const pageSections = mk_infoCard?.mk_sub_builder_array?.map(
    (builderItem, index) =>
      builderItem?._key && (
        <BuilderSection
          details={builderItem}
          key={builderItem?._key + index}
          moduleAnalyticsEventProperty={moduleAnalyticsEventProperty}
        />
      ),
  )

  const linkUrl = mk_infoCard?.mk_cardLink
  const isHovered = !!mk_infoCard?.mk_cardLink

  return (
    <ExploreItem isHovered={isHovered}>
      {linkUrl && (
        <CardLink
          name={mk_infoCard.mk_cardLink ?? ''}
          href={linkUrl}
          data-testid={'explore-link'}
          onClick={() => {
            const { cardLinkAnalyticsEventName, cardProductAnalyticsEventProperty } = mk_infoCard

            const linkData = mk_infoCard?.mk_sub_builder_array?.find(
              (item) => item?._type === 'mk_sub_cta' || item?._type === 'mk_sub_cta_htp',
            ) as MkCallToAction & MkSubCtaHtp

            const { mk_callToAction, mk_callToAction_text } = linkData

            logAmplitudeEvent(cardLinkAnalyticsEventName, linkUrl, {
              Module: moduleAnalyticsEventProperty,
              Placement: placementAnalyticsEventProperty,
              'Link Text': mk_callToAction ?? mk_callToAction_text,
              Product: cardProductAnalyticsEventProperty,
            })
          }}
        />
      )}
      <ExploreItemInner>
        {mk_infoCard?.mk_picture?.lgSrcSet?.asset?.url && mk_infoCard?.mk_picture?.smSrcSet?.asset?.url && (
          <picture>
            <source media="(max-width: 639px)" srcSet={mk_infoCard?.mk_picture?.smSrcSet?.asset?.url ?? ''} />
            <ExploreItemImage
              src={mk_infoCard?.mk_picture?.lgSrcSet?.asset?.url ?? ''}
              alt={mk_infoCard?.mk_picture?.altText ?? 'Image description'}
            />
          </picture>
        )}
        <ExploreItemContent
          exploreItemContentMaxWidth={mk_infoCard?.exploreItemContentMaxWidth ?? '100%'}
          mk_position={mk_infoCard?.mk_position ?? 'static'}
          data-testid={'exploreitemcontent-test-id'}
        >
          <div style={{ position: 'relative', zIndex: 20 }}>{pageSections}</div>
        </ExploreItemContent>
        {mk_infoCard?.mk_OverlayOpacityColor && mk_infoCard.mk_OverlayOpacity && (
          <Overlay
            overlayColor={mk_infoCard.mk_OverlayOpacityColor.value as string}
            overlayOpacity={mk_infoCard.mk_OverlayOpacity}
            data-testid={'overlay-test-id'}
          />
        )}
      </ExploreItemInner>
    </ExploreItem>
  )
}

export default ExploreCard
