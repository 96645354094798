import { MkSubBody as MkSubBodyType } from 'apollo/generated/wrapper/wrapper.schema.types'
import React from 'react'
import { MkSubBodyStyled } from './styled/MkSubBody.stlyed'

type MkSubBodyProps = {
  mk_sub_body: MkSubBodyType
}

const MkSubBody: React.FC<MkSubBodyProps> = ({ mk_sub_body }) => {
  return <MkSubBodyStyled mk_bodyColor={mk_sub_body.mk_bodyColor}>{mk_sub_body?.mk_body}</MkSubBodyStyled>
}

export default MkSubBody
