import { apolloOrchClient } from 'apollo/apolloClient'
import { Menu } from 'apollo/generated/orch/orch.schema.types'
import getMenus from 'apollo/queries/orch/getMenus.orch.graphql'
import { useEffect, useMemo, useState } from 'react'

type MenuResponse = {
  menuData: Record<string, Menu | undefined>
  isError: boolean
  isLoading: boolean
}

const useMenus = (menus: string[]): MenuResponse => {
  const [data, setData] = useState<Menu[] | null>(null)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        setIsLoading(true)
        const response = await apolloOrchClient.query<{ getMenus: Menu[] }>({
          query: getMenus,
        })
        setData(response.data.getMenus || [])
        setIsError(false)
      } catch (error) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
    fetchMenus()
  }, [])

  const menuData = useMemo(
    () =>
      data
        ? menus.reduce(
            (menuCollection, menu) => {
              const foundMenu =
                data.find((menuInfo) => menuInfo?.name?.toLowerCase() === menu.toLocaleLowerCase()) || undefined

              return {
                ...menuCollection,
                [menu]: foundMenu,
              }
            },
            {} as Record<string, Menu | undefined>,
          )
        : {},
    [data, menus],
  )

  return {
    menuData,
    isError,
    isLoading,
  }
}

export const useNavMenus = () => useMenus(['footer', 'terms', 'copyright'])

export default useMenus
