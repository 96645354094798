import { MkSubHeaderLarge as MkSubHeaderLargeGQL } from 'apollo/generated/wrapper/wrapper.schema.types'
import React from 'react'
import { StyledDiv, StyledImage } from './styled/MkSubHeaderLarge.styled'

type MkSubHeaderLargeProps = {
  mk_sub_header_large: MkSubHeaderLargeGQL
}

const MkSubHeaderLarge: React.FC<MkSubHeaderLargeProps> = ({ mk_sub_header_large }) => {
  return (
    <StyledDiv>
      <StyledImage
        src={mk_sub_header_large?.mk_sub_header_large?.asset?.url ?? ''}
        alt={mk_sub_header_large.mk_sub_header_large?.asset?.altText ?? ''}
      />
    </StyledDiv>
  )
}

export default MkSubHeaderLarge

