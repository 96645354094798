import { MkSubBodyHtp as MkSubBodyHtpGQL } from 'apollo/generated/wrapper/wrapper.schema.types'
import { StyledParagraph } from './styled/MkSubBodyHtp.styled'

type MkSubBodyHtpProps = {
  mk_sub_body_htp: MkSubBodyHtpGQL
}
const MkSubBodyHtp: React.FC<MkSubBodyHtpProps> = ({ mk_sub_body_htp }) => {
  return <StyledParagraph>{mk_sub_body_htp?.mk_body}</StyledParagraph>
}

export default MkSubBodyHtp

