import styles from './WhereCanYouPlay.module.scss'
import { useState } from 'react'
import WhereContent from './WhereContent'
import type {
  LocationsList,
  Maybe,
  MkProductOptions,
  MkWhereCanYouPlayTemplate,
} from 'apollo/generated/wrapper/wrapper.schema.types'
import Select from './Select/Select'
import SelectOption from './Select/SelectOption'
import Tabs from './Tabs/Tabs'
import ProductLocationsList from './ProductLocationsList/ProductLocationsList'
import Map from './Map/Map'
import { proximaNova } from '@/utils/fonts'

type WhereCanYouPlayProps = {
  mk_whereCanYouPlay: MkWhereCanYouPlayTemplate
}

const WhereCanYouPlay = ({ mk_whereCanYouPlay }: WhereCanYouPlayProps) => {
  const [current, setCurrent] = useState(0)
  const currentProduct = mk_whereCanYouPlay.products?.[current] ?? {}

  const data = {
    products: mk_whereCanYouPlay.products ?? [],
    currentProduct: {
      name: currentProduct?.productName ?? '',
      locations: currentProduct?.availableLocations ?? [],
      disclaimer: currentProduct?.disclaimerText ?? '',
      detailsUrl: currentProduct?.detailsUrl ?? '',
      availableLocations: currentProduct?.availableLocations ?? [],
    },
  }

  const getCurrentProductIndex = function (name: string): number {
    return data.products.findIndex((product) => product?.productName === name)
  }
  const numberOfStates = data.currentProduct.locations.filter((location) => location?.code).length ?? 0

  return (
    <div
      className={styles.wrapper}
      data-testid="where-can-you-play"
      style={{ fontFamily: `${proximaNova}, sans-serif` }} // Don't think we can set this in SCSS using Next/Font + Emotion setup
    >
      <div className={styles.section}>
        <h2
          className={styles.title}
          style={{ fontFamily: `${proximaNova}, sans-serif` }} // temp override of app.css styles
        >
          Where can I play?
        </h2>
        <Select
          handleOnChange={(e) => setCurrent(getCurrentProductIndex(e.target.value))}
          id="product"
          labelText="Product"
        >
          {data.products?.map((product) => (
            <SelectOption key={product?.productName} optionValue={product?.productName ?? ''} />
          ))}
        </Select>
        <Tabs tabsData={data.products as Maybe<MkProductOptions>[]} currentTab={current} setCurrentTab={setCurrent} />
        <WhereContent
          currentProduct={data.currentProduct.name}
          numberOfStates={numberOfStates}
          productDisclaimer={data.currentProduct.disclaimer}
          detailsLinkUrl={data.currentProduct.detailsUrl}
        />

        <div className={styles.content}>
          <ProductLocationsList
            locations={data.currentProduct.availableLocations as Maybe<LocationsList>[]}
            currentProduct={data.currentProduct.name}
          />
          <Map activeStates={data.currentProduct.availableLocations.map((location) => location?.code ?? '')} />
        </div>
      </div>
    </div>
  )
}

export default WhereCanYouPlay
