import { proximaNova } from '@/utils/fonts'
import styled from '@emotion/styled'
import { MkSubBodyContentLove } from 'apollo/generated/wrapper/wrapper.schema.types'

type StyledParagraphProps = Pick<MkSubBodyContentLove, 'mk_bodyColor'>

export const StyledParagraph = styled.p<StyledParagraphProps>`
  color: ${(props) => props.mk_bodyColor?.value};
  font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 15px;
`
