import { MkSubHeader as MkSubHeaderGQL } from 'apollo/generated/wrapper/wrapper.schema.types'
import React from 'react'
import { BadgeImage, ExploreItemHeadingStyle, HeaderImage } from './styled/MkSubHeader.styled'

type MkSubHeaderProps = {
  mk_subHeader: MkSubHeaderGQL
}

const MkSubHeader: React.FC<MkSubHeaderProps> = ({ mk_subHeader }) => {
  return (
    <ExploreItemHeadingStyle>
      <BadgeImage
        src={mk_subHeader?.mk_logoHeaderImage?.asset?.url ?? ''}
        alt={mk_subHeader?.mk_logoHeaderImage?.asset?.altText ?? ''}
      />
      <HeaderImage
        src={mk_subHeader?.mk_HeaderImage?.asset?.url ?? ''}
        alt={mk_subHeader?.mk_HeaderImage?.asset?.altText ?? ''}
      />
    </ExploreItemHeadingStyle>
  )
}

export default MkSubHeader
