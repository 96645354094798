import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'cross-fetch'

const cache = new InMemoryCache()
const wrapperUri = process.env.NEXT_PUBLIC_ENDPOINT
const orchUri = process.env.NEXT_PUBLIC_ORCH_ENDPOINT

const defaultOptions: DefaultOptions = {
  query: {
    errorPolicy: 'all',
  },
}

export const apolloWrapperClient = new ApolloClient({
  link: new HttpLink({ uri: wrapperUri, fetch }),
  cache,
  defaultOptions,
})

export const apolloOrchClient = new ApolloClient({
  link: new HttpLink({ uri: orchUri, fetch }),
  cache,
  defaultOptions,
})
