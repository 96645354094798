import { MkSpacer } from 'apollo/generated/wrapper/wrapper.schema.types'
import { nullPropsToUndefined } from '@/utils/nullUtils'
import useWindow from '@/utils/hooks/useWindow'
import { numericBreakpoints } from '@/utils/general'

export type SpacerProps = MkSpacer

const Spacer = (props: SpacerProps) => {
  const { windowWidth } = useWindow()

  const {
    backgroundColor,
    spacingBreakpointXxl,
    spacingBreakpointXl,
    spacingBreakpointLg,
    spacingBreakpointMd,
    spacingBreakpointSm,
    spacingBreakpointXsm,
    defaultSpacing,
  } = nullPropsToUndefined(props) // Styles don't support null values -- convert them before passing them in

  const getSpacing = () => {
    const { xsm, sm, md, lg, xl, xxl } = numericBreakpoints

    if (spacingBreakpointXxl !== undefined && windowWidth >= xxl) return spacingBreakpointXxl
    if (spacingBreakpointXl !== undefined && windowWidth >= xl) return spacingBreakpointXl
    if (spacingBreakpointLg !== undefined && windowWidth >= lg) return spacingBreakpointLg
    if (spacingBreakpointMd !== undefined && windowWidth >= md) return spacingBreakpointMd
    if (spacingBreakpointSm !== undefined && windowWidth >= sm) return spacingBreakpointSm
    if (spacingBreakpointXsm !== undefined && windowWidth >= xsm) return spacingBreakpointXsm
    return defaultSpacing
  }

  return <div style={{ backgroundColor: backgroundColor?.value, paddingBottom: getSpacing() }} data-testid="spacer" />
}

export default Spacer
